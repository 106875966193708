export const cl = {
  colorGroupMapping: {
    AMARILLO: '#fdec6f',
    AZUL: '#0b108c',
    'AZUL CLARO': '#94bfdc',
    BEIGE: '#cfb174',
    BLANCO: '#ffffff',
    BURDEO: '#5f021f',
    CAFÉ: '#8e5b3e',
    CELESTE: '#94bfdc',
    CREMA: '#fff8d4',
    FUCSIA: '#d90073',
    GRIS: '#dddddd',
    MORADO: '#6d165a',
    NARANJO: '#fc930a',
    NEGRO: '#000000',
    ROJO: '#ba2d1e',
    ROSADO: '#fcd9e5',
    VERDE: '#519548',
  },
  configurations: {
    adsConfig: {
      adProviderUrl:
        'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3ef14bb68a88d66c/ads_desktop_v3.html',
      xlp: [
        {
          slot: 'div-gpt-ad-1681222629103-0',
          folder: '/21632469435/PLP_Banner_Superior',
          container: 'google-banner-superior-div',
        },
        {
          slot: 'div-gpt-ad-1655991838659-0',
          folder: '/21632469435/plp_vertical',
          container: 'google-banner-izq-div',
        },
        {
          slot: 'div-gpt-ad-1655991534231-0',
          folder: '/21632469435/plp_horizontal_desktop',
          container: 'google-banner-inferior-desktop-div',
        },
      ],
      search: [
        {
          slot: 'div-gpt-ad-1681222629103-0',
          folder: '/21632469435/PLP_Banner_Superior',
          container: 'google-banner-superior-div',
        },
        {
          slot: 'div-gpt-ad-1655991838659-0',
          folder: '/21632469435/plp_vertical',
          container: 'google-banner-izq-div',
        },
        {
          slot: 'div-gpt-ad-1655991534231-0',
          folder: '/21632469435/plp_horizontal_desktop',
          container: 'google-banner-inferior-desktop-div',
        },
      ],
    },
    disabledStoresSponsoredProducts: { stores: ['tottus'] },
  },
  endpoints: {
    APERTURA_URL_INVALID_CMR:
      'https://www.falabella.com/falabella-cl/page/CMR-enlinea?staticPageId=28700002&from=bShR3VyENqOK/iZsx/hUW4owR5kjQSQtt/XRCU1Pf3rhnbHrJ75QVFx4rZoioZA7Druyh/M/DtI1I/gPmmejNaiDTql/XJr0Nrso6iy+PPtSAco+SCZAjoRj9QapowiHgPJruBhfVvV0Jdebsu4pvl8k5AR1a6Uf4OACMk5vpUTP/PbJtfiNL84QpaAdHM9kMdlDR4m/CUNHMSWXv8dgA1fqCuR1U4tNoyEocdM3NqzhscbJT1l+tQVaqJ6M8jJ1IUa2dl1wJQOeE1tSzTHfjy4jdLHemq4nxQiVxzWxe5gTfft90rmWuwFgt7Yu/yzvuo61qnL0Db8d8uI2RJTKFQ=&detalleClic=pdpsinOU',
    APERTURA_URL_VALID_CMR:
      'https://www.falabella.com/falabella-cl/page/CMR-enlinea?staticPageId=28700002&from=bShR3VyENqOK/iZsx/hUW4owR5kjQSQtt/XRCU1Pf3rhnbHrJ75QVFx4rZoioZA7Druyh/M/DtI1I/gPmmejNaiDTql/XJr0Nrso6iy+PPtSAco+SCZAjoRj9QapowiHgPJruBhfVvV0Jdebsu4pvl8k5AR1a6Uf4OACMk5vpUTP/PbJtfiNL84QpaAdHM9kMdlDR4m/CUNHMSWXv8dgA1fqCuR1U4tNoyEocdM3NqzhscbJT1l+tQVaqJ6M8jJ1IUa2dl1wJQOeE1tSzTHfjy4jdLHemq4nxQiVxzWxe5gTfft90rmWuwFgt7Yu/yzvuo61qnL0Db8d8uI2RJTKFQ=&detalleClic=pdpconOU',
    ATG_AUTH_BASEURL: 'https://www.falabella.com',
    CATALYST_AUTH_BASEURL: 'https://www.falabella.com',
    EXT_WARRANTY_LIGHTBOX_LINK: '/falabella-cl/page/garantia?staticPageId=11300003',
    RETURN_POLICY_LINK_URL: 'https://www.falabella.com/falabella-cl/page/excepciones-devoluciones-cambios',
    WISHLISTS_PAGE_URI: 'https://www.falabella.com/falabella-cl/myaccount/myLists',
    WORKING_HOURS_SODIMAC: 'https://sodimac.falabella.com/sodimac-cl/page/tiendas-y-horarios-sodimac',
    WORKING_HOURS_TIENDA: 'https://tienda.falabella.com/falabella-cl/page/horarios-tienda-Falabella',
    WORKING_HOURS_TOTTUS: 'https://tottus.falabella.com/tottus-cl/page/horario_tiendas',
  },
  facetLabels: {
    'f.derived.variant.activeEvent': '<p>CYBER</p>',
    'f.derived.variant.freeShipping': '',
    'f.shipping.hd_same_day_zones': '<p>Si compras hasta las&nbsp;<strong>16:00 horas</strong></p>',
  },
  textDictionary: {
    A11Y_LOCATION_LABEL: 'location icon',
    A11Y_USER_LABEL: 'user info',
    A11Y_VIDEO_LABEL: 'Play Video',
    A2C_CONTINUE_SHOPPING: 'Seguir comprando',
    A2C_ERROR_AL013: 'No se pudo agregar el ID de producto',
    A2C_ERROR_CART_CONFIG_BREACH: 'Se superó la cantidad máxima permitida por producto',
    A2C_ERROR_REMOVE_ITEM: 'Error al eliminar el artículo del carrito',
    A2C_EXT_WARRANTY: 'Ver beneficios',
    A2C_VIEW_BASKET: 'IR AL CARRO DE COMPRAS',
    AB_TEST_MATCH_VALUE: 'rltr_enabled',
    AB_TEST_VALUE_OVERRIDE: 'bi_ab__online_ltr_classifier',
    ACCEPT: 'ACEPTAR',
    ACCUMULATE: 'Acumula hasta',
    ACTIVATE_ADD_TO_BAG: 'HABILITAR AGREGAR AL CARRO',
    ADD_REBRANDING: 'Agregar',
    ADD_TO_BAG: 'Agregar al Carro',
    ADD_TO_BAG_MKP: 'Agregar al Carro',
    ADD_TO_CART: 'Ver producto',
    ADD_TO_CART_TEXT: 'Agregar al Carro',
    ADD_TO_CART_TEXT_ALONE: 'Agregar',
    ADD_TO_CART_TEXT_MKP: 'Agregar al Carro',
    ADD_TO_CART_TEXT_REBRANDING: 'Agregar al Carro',
    ADD_X_ITEM_TO_BAG: 'Agregar los {count} al Carro',
    ADD_X_ITEM_TO_BAG_REBRAND: 'Agregar al Carro',
    ADDING_TO_CART: 'Agregando...',
    ADDING_TO_CART_1: 'Agregando',
    ADDITIONAL_SERVICES_LABEL: 'Servicios adicionales',
    ANDROID_FACET_SUPPORT_VERSION: '10.0.0',
    ANDROID_VERSION_SINGLE_SELECT: '2.5.28',
    ASSOCIATED_SELLER_INFO:
      'Este es un producto publicado directamente por un vendedor nacional o internacional y cuenta con el respaldo de Falabella. En algunos casos podría tener plazos de despacho diferentes a los habituales.',
    ATC_ONLY_BUY_AT_STORE: 'Ver Producto',
    AVAILABILITY: 'Disponibilidad',
    AVAILABILITY_DELIVERY_TYPE: 'Tipo de entrega',
    AVAILABILITY_DOWNLOADABLE: 'Descargable',
    AVAILABILITY_IMMEDIATE_DELIVERY: 'Entrega inmediata',
    AVAILABILITY_PDP: 'Disponibilidad de producto',
    AVAILABILITY_SELECT_OPTION: 'Selecciona opción para ver disponibilidad',
    BACK_ROOM: 'trastienda',
    BAG: 'Bolsa',
    BIG_TICKETS_UNAVAILABLE:
      'Debido a su tamaño, este producto no está disponible para retiro en tienda, pero nosotros te lo llevamos sin problemas, intenta la opción ',
    BOOM_SHIPPING: 'Boom',
    BUNDLED_PRODUCT_LABEL: 'Juntos van mejor',
    BUNDLED_PRODUCT_TOTAL_PRICE_LABEL: 'Llévalos por',
    BUY_CONNECT_ONLINE: '¡Ahorra tiempo! Compra un Smartphone y contrata un plan, todo en línea y de una sola vez.',
    BV_RECOMMENDED_THRESHOLD: 80,
    CANCEL: 'Cancelar',
    CART_ASSEMBLY_OPTION_SEC_LABEL: '(Sólo RM radio urbano)',
    CATEGORIES: 'Categorías',
    CATEGORY_LATERAL_TEXT: `<p>%Category Name%</p>
    <p>Descubre un amplio catálogo de productos en Sodimac para %Category Name%. Encuentra todo lo que necesitas para renovar tu hogar. ¡Visítanos y encuentra inspiración para tus proyectos!</p>
    <p>Desde herramientas hasta accesorios, estamos aquí para ayudarte a hacer realidad tus ideas y renovar tus espacios, creando un ambiente único y personalizado. Explora nuestra selección de herramientas, materiales y accesorios de calidad que te ayudarán a crear un espacio más tú.</p>
    <p>Desde remodelaciones hasta proyectos de decoración, estamos aquí para hacer tus ideas realidad. ¡Visítanos y encuentra todo lo que tenemos para ofrecerte en %Category Name%!</p>
    <h2>Explora la variedad de productos de %Category Name% en Sodimac</h2>
    <p>Herramientas, materiales y accesorios de calidad para tus proyectos y renovación de espacios. ¡Visítanos y descubre todo lo que tenemos para ofrecerte!</p>
    <p>Encuentra una amplia variedad de productos de %Category Name% en Sodimac. Encuentra todo lo necesario para tus proyectos de renovación y decoración. ¡Visítanos y haz tus ideas realidad!</p>`,
    CHAT_BOT: 'ChatBot',
    CHAT_BOT_NAME_AR: 'Lucía',
    CHECK_ANOTHER_STORE: 'Revisar otra tienda',
    CHECK_THE_OFFERS: '¡Revisa las ofertas!',
    CHIP: 'CHIP',
    CHOOSE_LOCATION: 'Selecciona tu región',
    CHOOSE_OPTION: 'Selecciona una opción',
    CHOOSE_OPTION_LABEL: 'Opción rango de horario:',
    CHOOSE_VARIANTS: 'ELIGE TUS OPCIONES',
    CHOOSE_YOUR_PLAN: 'Elige tu equipo y plan',
    CIUDAD_TEXT: 'Partido',
    CLEAN: 'Limpiar Filtro',
    CLEAR: 'Cerrar',
    CLEAR_FACET_VALUES_MODAL: 'Limpiar filtros',
    CLEAR_FILTERS: 'Quitar filtros',
    CLICK_TO_GET_CALL_BUTTON: '¿NECESITAS ASESORÍA?',
    CLICK_TO_GET_CALL_BUTTON_SUBTITLE: '¡Te llamamos! Haz click',
    CLOSE_A11Y_LABEL: 'Close',
    CMR_CALCULATOR_ERROR_LOADING_TRY_AGAIN: 'Error al cargar. Intenta nuevamente.',
    CMR_DEFAULT_INSTALLMENTS: '10',
    CMR_Falabella: 'CMR Falabella.',
    CMR_INSTALLMENT_ERROR_MESSAGE: 'Error al cargar. Intenta nuevamente.',
    CMR_MAPPING:
      'WwogIHsKICAgICJ0aXRsZSI6ICJDb24gdGFyamV0YSBDTVIiLAogICAgImRpdmlkZUZhY3RvciI6IDIwMCwKICAgICJsb2dvIjogImljb25zLmNtclRhcmpldGFJY29uIgogIH0sCiAgewogICAgInRpdGxlIjogIkNvbiBEw6liaXRvIEJhbmNvIEZhbGFiZWxsYSIsCiAgICAiZGl2aWRlRmFjdG9yIjogNTAwLAogICAgImxvZ28iOiAiY29ucy5iYW5jb0ljb24iCiAgfSwKICB7CiAgICAidGl0bGUiOiAiQ29uIEN1ZW50YSBGcGF5IiwKICAgICJkaXZpZGVGYWN0b3IiOiA1MDAsCiAgICAibG9nbyI6ICJpY29ucy5mcGF5SWNvbiIKICB9LAogIHsKICAgICJ0aXRsZSI6ICJDb24gdGFyamV0YSBkZSBjcsOpZGl0byIsCiAgICAiZGl2aWRlRmFjdG9yIjogNzUwLAogICAgImxvZ28iOiAiaWNvbnMuY3JlZGl0b0ljb24iCiAgfSwKICB7CiAgICAidGl0bGUiOiAiQ29uIHRhcmpldGEgZGUgZMOpYml0byIsCiAgICAiZGl2aWRlRmFjdG9yIjogNzUwLAogICAgImxvZ28iOiAiaWNvbnMuZGViaXRvSWNvbiIKICB9Cl0=',
    CMR_POINTS: 'CMR Puntos',
    CMR_REMINDER: 'Planes disponibles solo con tu tarjeta CMR',
    CMRAPERTURATEXT_ALREADY_HAVE: 'Pídela y obtén $10.000 de descuento.',
    CMRAPERTURATEXT_BENEFITS: 'Ábrela ahora y obtén $10.000 de descuento.',
    CMRAPERTURATEXT_DONT_HAVE_IT: '¿Aún no la tienes?',
    CMRAPERTURATEXT_ONTHIS_PURCHASE: 'en esta compra',
    CMRAPERTURATEXT_OPEN_IT: ' Ábrela',
    CMRAPERTURATEXT_OPEN_YOUR_CMR: '¿Aún no tienes tu tarjeta CMR?',
    CMRAPERTURATEXT_REQUEST: '¿Aún no tienes tu CMR Banco Falabella?',
    CMRAPERTURATEXT_SAVE: 'AHORRA',
    CMRAPERTURATEXT_USING_CMR: 'USANDO TU CMR',
    CMRONLINEDECLINED: 'No se pudo terminar la solicitud. Para mas información dirígete a una sucursal CMR',
    CMRONLINESUCCESS: 'Tu Tarjeta CMR fue añadida a tus medios de pago.',
    CMRONLINESUCCESSNOTENROLLED:
      'Tu tarjeta CMR fue creada, pero no pudo ser anadida a tu cuenta, favor dirígete a una sucursal CMR',
    CMSIZE: 'cm',
    COLLECTIONS_TO_SHOW_PLANS: 'connect-products,prueba-connect,falabella-connect',
    COLOR: 'Color',
    COLORS_AVAILABLE: 'COLORES DISPONIBLES',
    COLORS_AVAILABLE_SMALL: 'Colores Disponibles',
    COMBO_CLARO_LABEL: 'Claro',
    COMBO_DEFAULT_LABEL: 'Selecciona',
    COMBO_ENTEL_LABEL: 'Entel',
    COMBO_GIGAS_LABEL: 'Gigas',
    COMBO_GTD_MANQUEHUE_LABEL: 'GTD Manquehue',
    COMBO_MINUTES_LABEL: 'Minutos',
    COMBO_MOVIL_FALABELLA_LABEL: 'Movil Falabella',
    COMBO_MOVISTAR_LABEL: 'Movistar',
    COMBO_NETLINE_LABEL: 'Netline',
    COMBO_OPERATOR_LABEL: 'Operador',
    COMBO_ORDER_BY_LABEL: 'Ordenar por',
    COMBO_PHONE_PRICE_LABEL: 'Precio de equipo',
    COMBO_PLAN_LABEL: 'Plan',
    COMBO_PLAN_PREPAGO_LABEL: 'Plan / Prepago',
    COMBO_PLAN_PRICE_LABEL: 'Precio de plan',
    COMBO_PREPAGO_LABEL: 'Prepago',
    COMBO_SIMPLE_LABEL: 'Simple',
    COMBO_VIRGIN_LABEL: 'Virgin',
    COMBO_VTR_LABEL: 'VTR',
    COMBO_WOM_LABEL: 'WOM',
    COMUNA: 'Comuna',
    CONNECT_AGENT: 'EvalFalConnectj6S9B30n104f94g',
    CONNECT_ASSESSMENT_FORM_ACCEPT: 'Acepto los ',
    CONNECT_ASSESSMENT_FORM_ACCEPT_BUTTON: 'REVISAR PLANES',
    CONNECT_ASSESSMENT_FORM_ERROR_CLIENT: 'ESTIMADO CLIENTE',
    CONNECT_ASSESSMENT_FORM_OPTION_KEEP_NUMBER: 'Mantener número',
    CONNECT_ASSESSMENT_FORM_OPTION_NEW_NUMBER: 'Número nuevo',
    CONNECT_ASSESSMENT_FORM_QUE_ES_ESTO: '¿Qué es esto?',
    CONNECT_ASSESSMENT_FORM_QUE_QUIERES_HACER: '¿QUÉ QUIERES HACER CON TU NÚMERO?',
    CONNECT_ASSESSMENT_FORM_RETRY_BUTTON: 'PROBAR CON OTRO RUT',
    CONNECT_ASSESSMENT_FORM_RETRY_BUTTON_REBRANDING: 'Probar con otro RUT',
    CONNECT_ASSESSMENT_FORM_RUT_PLACEHOLDER: '12.345.678-9',
    CONNECT_ASSESSMENT_FORM_RUT_TEXT: 'RUT',
    CONNECT_ASSESSMENT_FORM_SERIAL_NUMBER_PLACEHOLDER: '123.123.123',
    CONNECT_ASSESSMENT_FORM_SERIAL_NUMBER_TEXT: 'NÚMERO DE SERIE',
    CONNECT_ASSESSMENT_FORM_TERMS: 'Términos y condiciones',
    CONNECT_ASSESSMENT_FORM_TERMS_LINK:
      '/v3/assets/blt928898215b35efe2/blt618601d40a3f081e/Terminos-y-condiciones-cotizar-telefonia.pdf',
    CONNECT_ASSESSMENT_FORM_TITLE: 'Datos para la evaluación',
    CONNECT_AUTENTIA_ERROR_MESSAGE: 'Su cédula de identidad se encuentra vencida o numero de serie es incorrecto',
    CONNECT_AUTENTIA_SERVICE_CALL: 'https://fconnect.falabellatienda.com/api-txd/validaCliente/validaCi',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE: '¡Te llamamos!',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE_DESKTOP: '¡Te llamamos!',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE_MOBILE: '¡Venta Telefonica!',
    CONNECT_CLICK_TO_GET_CALLED_END_TIME: '21',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_ERROR: '¡Ha ocurrido un error vuelve a intentarlo mas tarde!',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_REQUEST_CALL: 'https://fconnect.falabellatienda.com/web-bff/solicita/llamada',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_TOKEN: 'https://fconnect.falabellatienda.com/api-txd/getTokenEncrypt',
    CONNECT_CLICK_TO_GET_CALLED_START_TIME: '09',
    CONNECT_CLICK_TO_GET_CALLED_UNAVAILABLE_TIME:
      'Nuestros ejecutivos no están disponibles en este momento. Te contactaremos próximamente para que puedas escoger el mejor plan para ti.',
    CONNECT_CMR_SERVICE_CALL: 'https://fconnect.falabellatienda.com/api-txd/cmr/consultaCmrInternet',
    CONNECT_ERROR_MESSAGE:
      'Lo sentimos, se ha producido un error confirmando tu compra. Por favor, comunícate con nuestro Servicio al Cliente al 223907910 o escríbenos a contacto@falabella.cl',
    CONNECT_ERROR_MESSAGE_BASKET:
      'Lo sentimos, no es posible añadir más productos a la Bolsa. Ya tienes agregado un producto de Falabella Connect. Este tipo de producto debe ser comprado de manera independiente, ya que tiene un despacho preferente.',
    CONNECT_ERROR_MESSAGE_BUTTON: 'Aceptar',
    CONNECT_GO_BACK: 'Volver atrás',
    CONNECT_IFRAME_HEIGHT: 713,
    CONNECT_IFRAME_URL: 'https://txd-fconnect-prd.appspot.com/#/?data=',
    CONNECT_IFRAME_WIDTH: 1080,
    CONNECT_IMPORTANT_THING_1: 'Para contratar tu plan tienes que tener tarjeta',
    CONNECT_IMPORTANT_THING_2:
      'Para contratar un plan necesitamos hacer una previa evaluación financiera. Por esto Promotora CMR Falabella S.A nos proporciona información necesaria para que podamos ofrecerte un plan adecuado y especial para ti. Si todavía no tienes tu CMR no dudes en sacarla.',
    CONNECT_IMPORTANT_THING_3: 'Si quieres portar tú número a otra compañía tienes que ser el titular de la línea.',
    CONNECT_IMPORTANT_THINGS:
      ' Esperamos ofrecerte los mejores planes y equipos, pero antes queremos recordarte dos cosas importantes:',
    CONNECT_LEGAL_TEXT:
      'Los términos y condiciones regulan la evaluación comercial de los planes de telefonía móvil ofrecidos por los operadores de telefonía móvil en nuestro sitio web www.falabella.com, que puedes contratar en conjunto con los teléfonos celulares comercializados por Falabella Retail S.A. Puedes revisar el contenido de los términos y condiciones de la evaluación comercial aqui.\n\nPara realizar la evaluación comercial, es necesario que seas titular de una tarjeta CMR, y que ésta haya sido emitida, con al menos, 90 días de antigüedad a la fecha de solicitud de evaluación o contratación del plan de telefonía móvil. Adicionalmente, validaremos la vigencia y la exactitud de los datos de tu cédula de identidad que nos proporciones.\n\nLos productos deben ser comprados de manera independiente y separada de otros productos que puedas tener en tu carro de compras. Si tienes productos adicionales en tu carro de compra, no te preocupes, éstos quedarán guardados para una compra futura.\n\nEl plan de telefonía y el precio del equipo pueden ser pagados con cualquier medio de pago disponible en falabella.com. El descuento en el precio del equipo está sujeto a la contratación del plan de telefonía respectivo y a la firma de un mandato para el cobro en tu tarjeta de crédito CMR, de la diferencia entre el precio del equipo y el precio prepago de este, según corresponda, el cual aplicará si das de baja el plan suscrito o contratas uno más económico dentro de un periodo de 18 meses para cada operador.\n\nDebido a los requerimientos y procesos del operador telefónico, existe un plazo máximo de 15 días para que recibas tu producto.',
    CONNECT_OPERATOR_SERVICE_CALL:
      'https://fconnect.falabellatienda.com/api-txd/credito/consultaInformacionCreditoInternet',
    CONNECT_OPERATORS_OPTION: 'Selecciona un operador',
    CONNECT_PLANS_CMR_ERROR_BLACK_LIST: 'No califica para comprar planes con falabella connect.',
    CONNECT_PLANS_CMR_INVALID: 'Su cédula de identidad se encuentra vencida o numero de serie es incorrecto',
    CONNECT_PLANS_LABEL: 'Planes',
    CONNECT_PORTABILITY_REMINDER: 'Si te quieres portar, debes ser el títular de tú número',
    CONNECT_QUESTION_THIRD_MODULE: '¿Tienes dudas?',
    CONNECT_TEXT_THIRD_MODULE:
      'Si necesitas asistencia de un especialista ingresa tus datos de contacto y te llamaremos dentro de unos minutos. Recuerda que también puedes comunicarte directamente llamando al 600 390 4100',
    CONTACT_OUR_SPECIALISTS: 'Te asesoramos por chat o teléfono',
    CONTRACT_PLAN: 'CONTRATAR PLAN',
    CONTRACT_PLAN_REBRANDING: 'Contratar plan',
    COOKIES_PAIR_TO_REMOVE: 'bi_ab::oltr_enabled',
    CURRENT_NUMBER_LABEL: 'Número Actual',
    CURRENT_NUMBER_PLACEHOLDER: '9 8765 4321',
    CURRENT_NUMBER_PREFIX: '+56 ',
    DELIVERY_TYPE_MOB_SUB: 'Compra online o compra en tienda',
    DELIVERYSLOT_CALENDAR_TIME_RANGE: 'Rango horario',
    DESPACHO: 'Despacho',
    DY_ALLOWED_SIS: 'sodimac',
    EMPTY_SEARCH_ERROR_MESSAGE: 'INGRESA LA PALABRA, PRODUCTO O CÓDIGO DE LO QUE QUIERES BUSCAR',
    EMPTY_SEARCH_ERROR_TITLE: 'Busqueda Vacia',
    ENTER_VALID_PRICE_ERROR_LABEL: 'Ingresa un rango de precios válido.',
    ENVIO_GRATIS_LABEL_AMOUNT: '$59.990',
    ERROR_COLOR_NOT_SELECTED: 'Seleccionar tu color para contratar el plan',
    ERROR_NO_OFFER_PER_OPERATOR:
      'No existen ofertas disponibles con este operador en la actualidad. ¡Revisa la oferta con otros operadores!',
    ERROR_SERVICE_NO_CREDIT:
      'No existen ofertas disponibles con este operador en la actualidad. ¡Revisa la oferta con otros operadores!',
    ERROR_SERVICE_NO_PLANS: 'Lo sentimos, no se han encontrado planes disponibles en esta evaluación',
    ERROR_SERVICE_SAME_OPERATOR:
      'No puedes realizar compra de un plan de tu misma compañía. ¡Revisa la oferta con otros operadores!',
    ERROR_SERVICE_TRY_AGAIN:
      '¡Intenta nuevamente! la conexión tardó más de lo esperado. Vuelve a intentar y revisa los planes que tenemos disponibles para ti.',
    EURSIZE: 'EUR',
    EVALUATE_PLANS_BUTTON: 'REVISAR PLANES',
    EVALUATE_PLANS_BUTTON_REBRANDING: 'Revisar planes',
    EXCLUDE_GSC_CATEGORY_ID_FOR_FREE_SHIPPING:
      'G200401,G200402,G200403,G200405,G200406,G200407,G20040804,G20040809,G20040813,G20040901,G20040906,G20040908,G20040914,G200410,G200411,G200412,G20041303,G20041213,G20041405,G20041406,G20041407,G20041410,G200415,G200416,G200417,G200418,G200419,G20042002,G20042006,G200421,G200422,G200423,G200424',
    EXPERT_ADVICE: 'ASESORÍA DE EXPERTOS',
    EXTENDED_WARRANTY_LABEL: 'Garantía extendida',
    ADD_TO_CART_ERROR:
      'Tuvimos un problema y no logramos agregar tus productos al carro. Por favor inténtalo nuevamente.',
    ADD_TO_CART_ERROR_AL023:
      'Has superado la cantidad máxima de unidades permitidas para este producto en el carrito. Por favor, ajusta la cantidad antes de proceder',
    ADD_TO_CART_ERROR_AL022:
      'Superaste la cantidad máxima de unidades permitidas para productos de este vendedor en el Carro. Por favor, ajusta la cantidad antes de proceder.',
    FAILED_ADDING_TO_CART_LABEL: 'Este producto ya no está disponible.',
    FAILED_ADDING_TO_CART_SERVICE:
      'En este momento no podemos agregar el Servicio adicional seleccionado. Por favor inténtalo nuevamente más tarde.',
    FALLBACK_SEO_BRAND_DESCRIPTION:
      'Compra online artículos y productos de $xlp_name$, conoce las diferentes opciones que tenemos para ti en tus marcas favoritas.',
    FALLBACK_SEO_BRAND_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_BRAND_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_BRAND_TITLE: '$xlp_name$',
    FALLBACK_SEO_BRAND_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_BRAND_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_CATEGORY_DESCRIPTION:
      'Compra online $xlp_name$ de tu marca favorita en falabella.com Conoce nuestro catálogo de $xlp_name$ y elige la opción perfecta para ti.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_BRAND:
      'Adquiere $xlp_name$ $facet_value$ de manera segura y fácil en falabella.com Conoce nuestro cátalogo online y haz la mejor elección.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_TYPE:
      'Encuentra lo último de $facet_value$ en $xlp_name$. Elige el modelo perfecto para ti y realiza tus compras online de manera segura en falabella.com',
    FALLBACK_SEO_CATEGORY_TITLE: '$xlp_name$',
    FALLBACK_SEO_CATEGORY_TITLE_BRAND: '$xlp_name$ $facet_value$',
    FALLBACK_SEO_CATEGORY_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_CATEGORY_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_CATEGORY_TITLE_TYPE: '$xlp_name$ $facet_value$',
    FALLBACK_SEO_COLLECTION_DESCRIPTION:
      'Encuentra $xlp_name$ de tu marca favorita en falabella.com. Compra online con descuentos y envios gratis en productos seleccionados.',
    FALLBACK_SEO_COLLECTION_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_COLLECTION_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_COLLECTION_TITLE: '$xlp_name$',
    FALLBACK_SEO_COLLECTION_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_COLLECTION_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_SELLER_DESCRIPTION:
      'Compra lo mejor de $xlp_name$ a un solo click. Encuentra esto y todo lo que necesitas en Falabella y Falabella.com!',
    FALLBACK_SEO_SELLER_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_SELLER_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_SELLER_TITLE: '$xlp_name$',
    FALLBACK_SEO_SELLER_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_SELLER_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FEATURE_FILTER_MODAL_TITLE: 'Selecciona un filtro',
    FILL_YOUR_DETAILS: 'Completa tus datos y firma',
    FILTERS_AVAILABILITY_TITLE_MOBILE: 'Entrega',
    FILTERS_TITLE: 'Filtrar',
    FILTERS_TITLE_MOBILE: 'Filtrar',
    FILTERS_TITLE_REBRANDING_MOBILE: 'Filtrar por:',
    FREE_SHIPPING_PRICE: '59990',
    FREE_SHIPPING_PRICE_3P: '19990',
    GO_BACK_TO_PLANS: 'Volver a planes',
    GOOGLE_ELEMENT_UNIQUE_ID: 'div-gpt-ad-3024305',
    HELP: 'ayuda',
    HIRE: 'CONTRATA TU',
    HIRE_YOUR_PLAN: 'Datos para contratar tu plan',
    HOME: 'Home',
    HOME_DELI_AVAILABLE_RESULT: 'Fechas y horarios de despacho disponibles',
    HOME_DELI_DATE_RANGE: 'Opción rango de fechas:',
    HOME_DELI_OUTOFSTOCK: 'No disponible para despacho a domicilio',
    HOME_DELI_RESULT_WILL_COME_HERE: 'Tus fechas más cercanas aparecerán aquí',
    HOME_DELI_SAVE: 'GUARDAR DIRECCIÓN',
    HOME_DELI_SEE_AVAILABILITY: 'Ver envío',
    HOME_DELI_SEE_MORE_OPTIONS: 'Ver más opciones',
    HOME_DELI_STORE_PLEASE_SELECT: 'Revisar disponibilidad',
    HOME_DELI_SUB_PREFIX: 'A',
    HOME_DELI_TITLE: 'Despacho a domicilio',
    HOME_DELIVERY_SHIPPING: 'Despacho a domicilio.',
    HOME_SHIPPING: 'Envío a domicilio',
    HOW_WORKS_CONNECT: '¿Cómo funciona Falabella Connect?',
    HOW_WORKS_CONNECT_TITLE: 'Términos y condiciones Falabella Connect',
    IDEAL_PLAN_LABEL: 'Tenemos tu plan ideal',
    INCORRECT_SEARCH_ERROR_MESSAGE: 'INGRESA LA PALABRA, PRODUCTO O CÓDIGO DE LO QUE QUIERES BUSCAR',
    INCORRECT_SEARCH_ERROR_TITLE: 'Busqueda Incorrecta',
    INTERNATIONAL_DELIVERY: 'Compra internacional.',
    INTERNATIONAL_SHIPPING: 'Envío Internacional',
    INTERNATIONAL_SHIPPING_XLP: 'Compra internacional',
    IOS_FACET_SUPPORT_VERSION: '10.0.0',
    IOS_VERSION_SINGLE_SELECT: '2.5.24',
    ITEM_ADDED_A11Y_LABEL: 'Item Added',
    ITEM_ADDED_IN_CART: 'Producto(s) agregado(s) al carro',
    LOADER_MESSAGE: 'Estamos procesando tu solicitud. Por favor espera un momento',
    LOCATION_DIALOG_BODY: 'Selecciona la región donde quieres ver la disponibilidad de productos.',
    LOCATION_DIALOG_HEADER: 'Cambia tu región',
    LOG_OUT_LABEL: 'Cerrar sesión',
    LOGIN: 'Inicia sesión',
    LOGIN_EMAIL_PLACEHOLDER: 'Ingresa tu e-mail',
    LOGIN_EMPTY_EMAIL: 'Por favor ingresa tu e-mail.',
    LOGIN_FACEBOOK: 'Ingresa Con Facebook',
    LOGIN_FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    LOGIN_HEADER_DESC: 'Compra más rápido y revisa los detalles de tus órdenes',
    LOGIN_INVALID_EMAIL: 'Formato de email inválido nombre@ejemplo.com',
    LOGIN_INVALID_PASSWORD: 'Formato de contraseña inválido',
    LOGIN_LABEL: 'Inicia Sesión',
    LOGIN_LOG_IN: 'Iniciar sesión',
    LOGIN_NOT_REGISTERED: '¿No tienes cuenta?',
    LOGIN_OR: 'ó',
    LOGIN_PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
    LOGIN_SIGN_UP: 'Regístrate',
    LOW_STOCK_WARNING: 'Los productos podrían agotarse próximamente. Cómpralos pronto.',
    MARKET_PLACE: 'Marketplace',
    MAX_FACET_LIMIT: 1500,
    MAXIMUM_QUANTITY_LIMIT_LABEL: 'La cantidad ingresada excede el stock del producto.',
    MENU: 'Menu',
    MODEL_CHARACTERISTICS_PREFIX: 'Medidas de modelo',
    MODEL_CHARACTERISTICS_PREFIX_MOBILE: 'Medidas de modelo',
    MONTHLY_PLAN_LABEL: 'Plan mensual desde',
    MORE_OPTIONS: 'Ver opciones',
    MORE_PRODUCTS_FROM_COLLECTION: 'Juntos van mejor',
    MY_ACCOUNT: 'Mis ordenes',
    MY_ACCOUNT_LABEL: 'Mi Cuenta',
    MY_ORDERS: 'Mis órdenes',
    MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
    NEED_HELP: '¿Necesitas ayuda?',
    NEED_HELP_CALL_US_AT: '¿Necesitas ayuda? Llámanos al',
    NEED_HELP_CALL_US_AT_PLAN: '¿Necesitas ayuda para contratar un plan? Llámanos al',
    NEED_RUT: 'Tenemos ofertas especiales. Solo necesitamos tu RUT para mostrártelas.',
    NEW_BADGE: 'Nuevo',
    NEXT: 'Siguiente',
    NEXT_DAY_SHIPPING_MIN_MAX_HOURS: '20,4',
    NO_ACCOUNT_LABEL: '¿No tienes cuenta?',
    NO_PLAN_LABEL:
      'Por ahora no contamos con planes disponibles para este teléfono. Te recomendamos revisar otros teléfonos para que elijas el que más te guste y lo puedas llevar con el plan de tu preferencia.',
    NO_PLAN_TITLE: '¡Lo sentimos!',
    NO_RESULT_LABEL: 'Lo sentimos, no encontramos resultados para',
    OCULTAR_LABEL: 'Ocultar',
    OF: 'de',
    OFFER_PRICE_LABEL: '',
    OFFER_PRICE_WARRANTY_LABEL: '(Oferta)',
    OK_TO_SHOP_LABEL: 'Ficha obtenida de',
    ONLY_BUY_AT_STORE: 'Compra solo en tienda',
    ORDER_BY: 'Ordenar',
    ORDER_BY_DESKTOP: 'Ordenar por',
    ORDER_BY_MOBILE: 'Ordenar',
    OTHER_SEARCHES_LABEL: 'Otras posibles búsquedas:',
    OUT_SERVICE:
      'En estos momentos no fue posible atender su solicitud. Por favor vuelve a intentar dentro de unos minutos.',
    OWN_INT_SELLER_INFO:
      "Este producto está publicado directamente por {{tienda}} y cuenta con el respaldo de <b style='font-weight: bold'>Falabella.com</b>. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.<br/><br/><b style='font-weight: bold'>El precio del producto incluye el costo de envío internacional y pago de impuestos de internación</b> (aduana). Al ser internacional pasa por una inspección y, en algunos casos, esto podría generar atrasos en la entrega.",
    OWN_SELLER_INFO:
      "Producto publicado directamente por <b style='font-weight: bold; text-transform: capitalize;'>{{tienda}}</b>.",
    PAGE_ERROR_TITLE:
      'Lo sentimos, estamos presentando problemas técnicos. Por favor usa la navegación por categorías.',
    PAGE_TITLE: 'Falabella.com - Mejor Compra Online',
    PAGES_LABEL: 'Páginas',
    PAY_AT_BOX: 'PAGAR EN LA CAJA',
    PDP_BI_AB_COOKIE: 'pdp_ab',
    PDP_NO_THANKS: 'No, gracias',
    PDP_STARTS_FROM: 'A partir de: ',
    PER_UNIT_PRICE_PREFIX: 'Unidad a',
    PERFUME_MERCHANT_CATEGORY_IDS: 'J08020101,J08020102,J08050101',
    PICKUP_INSTORE_AVAILABLE_RESULT: 'Fechas y horarios de despacho disponibles',
    PICKUP_INSTORE_AVAILABLEFROM: 'Disponible a partir',
    PICKUP_INSTORE_CHECK_ANOTHER_STORE: 'Revisar otra tienda',
    PICKUP_INSTORE_CLOSE_BUTTON: 'CERRAR',
    PICKUP_INSTORE_INFO_CAN_VARY: 'La información de disponibilidad puede variar durante lacompra.',
    PICKUP_INSTORE_NO_RESULT_AVAILABLE: 'Lo sentimos, no se encontraron tiendas disponibles',
    PICKUP_INSTORE_NOT_AVAILABLE_IN: 'No disponible en ',
    PICKUP_INSTORE_OUTOFSTOCK: 'No disponible para retiro',
    PICKUP_INSTORE_PLEASE_SELECT: 'Selecciona un punto de retiro',
    PICKUP_INSTORE_RESULT_WILL_COME_HERE: 'Tus tienda más cercanas aparecerán aquí',
    PICKUP_INSTORE_SAVESTORE_BUTTON: 'GUARDAR TIENDA',
    PICKUP_INSTORE_SEE_AVAILABILITY: 'Ver retiro',
    PICKUP_INSTORE_SEE_MORE_OPTIONS: 'Ver más opciones',
    PICKUP_INSTORE_STORES_AVAILABLE: 'Puntos de retiro disponibles',
    PICKUP_INSTORE_SUB_PREFIX: 'En',
    PICKUP_INSTORE_TITLE: 'Retira tu compra',
    PICKUP_OR_DELIVERY:
      'Retira tu compra en cualquiera de nuestras tiendas. Si firmaste en línea, recibe tu Smartphone donde quieras y disfrútalo.',
    PLAN: 'PLAN',
    PLAN_REVIEW_LABEL:
      'Revisa los planes disponibles de los distintos operadores que tenemos para ti con este teléfono.',
    PLANES: 'PLANES',
    PLANS_CARD_ONLINE: 'ONLINE',
    PLANS_CARD_SUBTITLE: 'Ingresa tus datos y descubre las mejores ofertas para ti. (Necesitas tu cédula de identidad)',
    PLANS_CARD_SUBTITLE_MODAL: 'Ingresa tus datos y encuentra las mejores ofertas para ti, con todas las compañias.',
    PLANS_CARD_TITLE: 'CONTRATA TU PLAN',
    PLANS_CLICK_TO_GET_CALLED_BUTTON_COMUNICATE: 'COMUNÍCATE AHORA',
    PLANS_CLICK_TO_GET_CALLED_BUTTON_REBRANDING: 'Comunícate ahora',
    PLANS_CLICK_TO_GET_CALLED_FORM_INPUT_NUMBER: '+56',
    PLANS_CLICK_TO_GET_CALLED_FORM_NAME: 'Nombre',
    PLANS_CLICK_TO_GET_CALLED_FORM_NUMBER: 'Número de contacto',
    PLANS_CLICK_TO_GET_CALLED_FORM_PH_NAME: 'JUANA RÍOS',
    PLANS_CLICK_TO_GET_CALLED_FORM_PH_NUMBER: '987654321',
    PLANS_CLICK_TO_GET_CALLED_NUMBER: 'O llámanos al 600 3904100',
    PLANS_CLICK_TO_GET_CALLED_OK_SUBTITLE:
      'En minutos un ejecutivo estará contactándose para que puedas escoger el mejor plan para ti.',
    PLANS_CLICK_TO_GET_CALLED_OK_TITLE_END: 'recibimos tu solicitud!',
    PLANS_CLICK_TO_GET_CALLED_OK_TITLE_SIGN: '¡',
    PLANS_CLICK_TO_GET_CALLED_SUBTITLE:
      'Si necesitas asistencia de un especialista ingresa tus datos de contacto y te llamaremos dentro de unos minutos.',
    PLANS_CLICK_TO_GET_CALLED_TITLE: '¡No te quedes sin tu plan!',
    PLANS_DETAILS_FREE_LABEL: 'LIBRES',
    PLANS_DETAILS_FREE_MINUTES_LABEL: 'MINUTOS LIBRES',
    PLANS_DETAILS_GB_LABEL: 'GB',
    PLANS_DETAILS_MINUTES_LABEL: 'MINUTOS',
    PLANS_DETAILS_MONTH_LABEL: ' meses',
    PLANS_DETAILS_MONTHLY_COST_LABEL: 'Cargo fijo mensual',
    PLANS_DETAILS_NEED_EVALUATION_LABEL: 'REVISA TUS PLANES',
    PLANS_DETAILS_PRICE_LABEL: 'Valor equipo',
    POD_BOOM_SHIPPING_LABEL: 'Boom',
    PREPAID_DEVICE: 'Equipo prepago desde',
    PREPAID_OPTION: 'Ver la opción prepago',
    PREVIOUS: 'Anterior',
    PRICE_NO_RESULTS_ERROR_LABEL: 'Rango sin resultados. Por favor ingresa otros valores.',
    PRICE_RANGE_FILTER_BUTTON: 'Filtrar',
    PRICE_RANGE_FROM: 'Desde',
    PRICE_RANGE_TO: 'Hasta',
    product: 'Product',
    PRODUCT_ADDED: 'Producto(s) agregado',
    PRODUCT_CODE: 'Código del producto',
    PRODUCT_INFORMATION_LABEL: 'Información adicional',
    PRODUCT_ORCHESTRATOR_FLOW: 'false',
    PRODUCT_OUTOFSTOCK: '¡Qué mal! Justo se agotó',
    PRODUCT_OUTOFSTOCK_TRY_OTHERS: 'Prueba buscando otros productos en',
    PRODUCT_WITHOUT_RESULT_TITLE: 'El producto que estás buscando ya no está disponible.',
    PROMOTED_FILTER_FOR: 'Filtrar por ',
    PUM_AFFIX: ' a ',
    PURCHASE_ONLINE_OUTOFSTOCK: 'No Disponible para compra en internet',
    PURCHASE_ONLINE_TITLE: 'Disponible para compra en internet',
    RATINGS_STAR_MORE_TEXT: 'y más',
    RATINGS_STAR_ONLY_TEXT: 'solamente',
    REACONDITIONED_LINK:
      'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/blt6e6552e1707e37fd/productos-reacondicionados.pdf',
    READY: 'Aplicar',
    RECEIVE_AND_ENJOY: '¡Recibe y disfruta!',
    RECOMMENDED_PRODUCT_LABEL: 'Clientes que vieron este producto tambieñ vieron',
    REGION: 'Región',
    REGIONS: 'Regiones',
    REGISTER_LABEL: 'Registrate',
    relatedBrands: 'MARCAS RELACIONADAS',
    relatedCategories: 'CATEGORÍAS RELACIONADAS',
    REQUEST_ACCEPTED: 'SOLICITUD ACEPTADA',
    REQUEST_ACCEPTED_MSG_1: 'Favor retirar sus productos en',
    REQUEST_PRODUCT: 'Solicitar Producto',
    RESULTS: 'Resultados',
    RETURN_POLICY_HEADING: 'Devuelve gratis por Derecho a retracto o Satisfacción garantizada.',
    RETURN_POLICY_INFO_HARDLINE: 'Tienes 30 días desde que lo recibes. ',
    RETURN_POLICY_INFO_SHOES: 'Tienes 30 días desde que lo recibes. ',
    RETURN_POLICY_INFO_SOFTLINE: 'Tienes 30 días desde que lo recibes. ',
    RETURN_POLICY_LINK_TEXT: 'Conoce los plazos y exclusiones',
    RETURN_POLICY_LINK_URL: 'https://www.falabella.com/falabella-cl/page/excepciones-devoluciones-cambios',
    RETURN_POLICY_MOBILE_HEADER: 'Politica de cambios y devoluciones',
    REVIEW_CONTRACT: 'Revisa el mandato y contrato. Elige la modalidad de firma que más te acomode.',
    SAME_DAY_DELIVERY_LABEL: 'Si compras hasta las',
    SAME_DAY_DELIVERY_LABEL_AMOUNT: '16:00 horas',
    SAME_DAY_DELIVERY_TIMELINE: 'llega hoy',
    SAME_DAY_FACET_HOURS: '15',
    SAME_DAY_FACET_MINUTES: 59,
    SAVE: 'Guardar',
    SEARCH_IN_FACETS_LABEL: 'search in facets',
    SEARCH_RESULTS_FOR: 'Resultados de la búsqueda',
    SEARCH_WITHOUT_RESULT_DESCRIPTION:
      'Tal vez su búsqueda fue demasiado específica, intente buscar con un término más general.',
    SEARCH_WITHOUT_RESULT_TITLE: 'Lo sentimos, no encontramos resultados para',
    searchPlaceholder: '¿Qué buscas?',
    SEE_COLLECTION_BUTTON_LABEL: 'VER COLECCIÓN',
    SEE_COMMENTS: 'Ver comentarios',
    SEE_LESS: 'ver menos',
    SEE_LESS_FACET_VALUES: '+ Ver menos',
    SEE_MORE: 'ver más',
    SEE_MORE_FACET_VALUES: '+ Ver más',
    SELECT_OPERATOR_ALERT_LABEL: 'El precio puede variar según Chip prepago y/o color',
    SELECT_OPERATOR_LABEL: 'Selecciona Chip prepago (incluido):',
    SELECT_OPTION: 'ELIGE TUS OPCIONES',
    SELECT_PROMOTION_TEXT: 'Seleccionar Promoción',
    SELECT_VARIANT_CHOOSE_OPTIONS: 'ELIGE TUS OPCIONES',
    SELECT_VARIANT_CHOOSE_OPTIONS_REBRANDING: 'Elige tus opciones',
    SELECT_VARIANT_WARNING_MESSAGE: 'Selecciona tus opciones para agregar el producto al carro',
    SELECTED_FILTERS: 'Filtro seleccionado',
    SELECTED_FILTERS_MOBILE: 'Filtros:',
    SELECTION_SIZE: 'Selecciona Talla',
    SELECTION_TIENDA: 'Selecciona la talla para ver disponibillidad en tienda:',
    SELLER_AND_SHIPPED_BY: 'y enviado por',
    SELLER_SKU_ID: 'Cód. tienda',
    SELLER_SOLD_BY: 'Vendido por',
    sellerMuteValue: 'LINIO',
    SHARE_FIND_IN: 'Encuéntralo en',
    SHARE_I_WANT: '¡Lo quiero!',
    SHARE_LIKE_PRODUCT: 'Me gustó este producto',
    SHIPPING_LABELS: 'Llega hoy,Llega mañana,Envío gratis',
    SHOW_OFFER_BUTTON: 'VER TODA LA OFERTA',
    SHOW_OFFER_BUTTON_REBRANDING: 'Ver toda la oferta',
    SHOWING_RESULT_FOR_LABEL: 'Mostrando resultados para',
    SIZE: 'TALLA',
    SIZE_CHART: 'Tabla de tallas',
    SIZE_CHART_NAME: 'TABLA DE TALLAS',
    SKU_EQUIPMENT: 'SKU Equipo',
    SKU_PLAN: 'SKU Plan',
    SKU_SEARCH_REGEX:
      '(^[0-9]{5,})+$|(^prod[0-9]+$)|(^sku[0-9]+$)|(^[0-9]{4,}X+$)|(^[0-9]{7,})+C$|C[0-9]{1,2}$|(^[0-9]{3,6})+XC$|XC[0-9]$',
    SLP_BI_AB_COOKIE: 'search_ab',
    SPECIFICATIONS_LABEL: 'Especificaciones',
    SPECIFY_YOUR_SEARCH_LABEL: 'Especifica tu búsqueda:',
    SPONSORED_PRODUCT_SLOTS: '[0, 10.9, 50, 100]',
    SPONSORED_PRODUCT_SLOTS_APP: '[0, 14.28, 42.8, 71.2]',
    STOCK: 'STOCK',
    STORE_PICKUP_SAVE: 'GUARDAR TIENDA',
    SUBSCRIPTION_ACCEPT_TERMS: 'Debes aceptar los terminos y condiciones',
    SUBSCRIPTION_EMPTY_ERROR: 'Por favor ingresa un mail',
    SUBSCRIPTION_INVALID_EMAIL_ERROR: 'Debes ingresar un mail valido',
    SUMMARY_MODAL_NOTICE: 'El valor del plan lo podrás pagar en el sitio web del operador o directamente en la tienda.',
    SURVEY_TEXT_ANY_MISTAKE: '¿Encontraste algún error?',
    SURVEY_TEXT_HELP_US: 'Ayúdanos a mejorar tu experiencia',
    SURVEY_TEXT_TELL_US: '¡Cuéntanos!',
    TEAM: 'EQUIPO',
    TEAM_FROM_LABEL: 'Equipo con plan desde',
    THIRD_PARTY_INT_SELLER_INFO:
      "Este producto está publicado directamente por un vendedor internacional y cuenta con el respaldo de <b style='font-weight: bold'>Falabella.com</b>. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.<br/><br/><b style='font-weight: bold'>El precio del producto incluye el costo de envío internacional y pago de impuestos de internación</b> (aduana). Al ser internacional pasa por una inspección y, en algunos casos, esto podría generar atrasos en la entrega.",
    THIRD_PARTY_SELLER_INFO:
      "Este producto está publicado directamente por un vendedor nacional y cuenta con el respaldo de <b style='font-weight: bold'>Falabella.com</b>. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.",
    TOGETHER_PRODUCTS: 'Van bien juntos',
    toggleViewButtonText: 'Visualizar por:',
    TOP_SPECIFICATIONS: 'Especificaciones principales',
    TRACK_ORDER: 'Seguimiento de mi orden',
    TYPE_C_HOME_DELI_OUTOFSTOCK: 'No disponible para despacho a domicilio',
    TYPE_C_HOME_DELI_OUTOFSTOCK_MKP: 'No disponible para envío a domicilio',
    TYPE_C_HOME_DELI_TITLE: 'Disponible para despacho a domicilio',
    TYPE_C_HOME_DELI_TITLE_MKP: 'Envío a domicilio',
    TYPE_C_PICKUP_INSTORE_OUTOFSTOCK: 'No disponible para retiro en tienda',
    TYPE_C_PICKUP_INSTORE_OUTOFSTOCK_MKP: 'No Retiro en tienda',
    TYPE_C_PICKUP_INSTORE_TITLE: 'Disponible para retiro en tienda',
    TYPE_C_PICKUP_INSTORE_TITLE_MKP: 'Retiro en tienda',
    UKSIZE: 'UK',
    UNITS_AVAILABLE_LABEL: 'Unidades disponibles',
    USSIZE: 'US',
    VENDER_CANCEL_BUTTON_LABEL: 'CANCELAR',
    VENDOR_BARCODE_CENTER_LABEL: 'Centra tu código de barras y evita mover tu teléfono.',
    VENDOR_CLIENT_LABEL: 'Cliente',
    VENDOR_HOME_LABEL: 'VENDOR HOME',
    VENDOR_INFORMATION_NOT_AVAILABLE_LABEL: 'Informacion no disponible',
    VENDOR_INVALID_CODE_LABEL: 'Lo sentimos, no hemos podido encontrar el producto escaneado.',
    VENDOR_LABEL: 'Vendedor',
    VENDOR_LOADING_LABEL: 'Buscando..',
    VENDOR_LOGOUT_LABEL: 'Cerrar sesión',
    VENDOR_NEW_CUSTOMER_SEARCH_LABEL: 'Buscar nuevo cliente',
    VENDOR_PRICE_LABEL: 'Precio',
    VENDOR_PRODUCT_AVAILABLE_LABEL: 'Producto disponible en tienda',
    VENDOR_SCAN_ANOTHER_PRODUCT_LABEL: 'ESCANEAR OTRO PRODUCTO',
    VENDOR_SCAN_INFO_LABEL: 'El escaneo es automático, evita brillos y reflejos.',
    VENDOR_SCAN_LABEL: 'Scan',
    VENDOR_STOCK_LABEL: 'Stock',
    VENDOR_STORE_LABEL: 'Tienda',
    VIEW_MORE_SPECIFICATONS: 'Ver más especificaciones',
    VIEW_PLAN: 'Ver Plan',
    VIEW_PLAN_REBRANDING: 'Ver plan',
    VIEW_PLANES_LABEL: 'VER PLANES',
    VIEW_PLANES_LABEL_REBRANDING: 'Ver planes',
    VIEW_PREPAID: 'Ver Prepago',
    VIEW_RESULTS_FACET_MODAL: 'Ver resultados',
    VOLVER_A_REVISAR: 'Volver a revisar',
    WANT_THIS_PLAN_BUTTON: 'QUIERO ESTE PLAN',
    WANT_THIS_PLAN_BUTTON_REBRANDING: 'Quiero este plan',
    WARNING_A11Y_LABEL: 'Warning',
    WELCOME_LABEL: 'Bienvenid@,',
    WHY: '¿Porqué?',
    WHY_PDP: '¿Por qué?',
    WITHDRAW_AT_STORE_LABEL_AMOUNT: '16:00 horas',
    WITHDRAWAL_AT_STORE: 'Retiro en un punto',
    WITHDRAWAL_AT_STORE_LABEL: 'Si compras hasta las',
    WITHDRAWAL_AT_STORE_TIMELINE: 'retira hoy',
  },
  toggles: {
    '"isRecoV2Enabled": true,': false,
    adInjectorIsEnabled: false,
    disableGeofinderModalForMobile: true,
    disableQuantitySelection: false,
    disableSponsoredProductsPDP: false,
    disableSponsoredProductsSisPDP: false,
    featureEnableSponsoredDisplay: true,
    freeShippingOnZonesEnabled: true,
    hasFreeShippingForInternationalProducts: true,
    identifyBuyAtStore: false,
    indexAndCrawlSLTPagesToggle: true,
    is3PFreeShippingEnabled: true,
    isAbCookieEnabled: false,
    isAbCookieEnabledBrand: false,
    isAbCookieEnabledCLP: false,
    isAbCookieEnabledPLP: false,
    isAbCookieEnabledSeller: false,
    isAbCookieEnabledSLP: false,
    isActiveTabAssistentePersonal: false,
    isAdditionalServiceFilteredForSodimac: false,
    isAddtionalInfoForSodimacEnabledInUI: true,
    isAddtionalSellerTextEnabled: true,
    isAIExperimentEnabled: true,
    isBestSellerEnabled: true,
    isBoomVisibleInFacet: false,
    isBoomVisibleInPod: false,
    isBreadcrumbsV2Enabled: true,
    isBVQuestionsEnabled: false,
    isCartQuantityLimitsEnabled: false,
    isCatalogV2EndpointActive: true,
    isCategoryFacetConfiguredAsGlobalFacet: true,
    isCFMediasEnabled: false,
    isChatBotVisible: false,
    isCMRAperturaEnabled: true,
    isCMRAperturaInNewPageEnabled: true,
    isCMRAperturaUrlAddonsEnabled: true,
    isCMRButtonWithPriceVisibleInPDP: true,
    isCMRCalculatorEnabledPDP: false,
    isComboUiEnabled: true,
    isComparativeTableEnabled: true,
    isComparativeTableSameSellerEnabled: false,
    isCustomABTestFlagEnabled: false,
    isCustomPDPABTestFlagEnabled: false,
    isDYEnabled: true,
    isEventToggleVisible: false,
    isFacetCountHidden: true,
    isFacetWithSwitchVisible: true,
    isFastShippingTextEnabled: true,
    isFloorcalculatorEnabled: false,
    isGenericCalculatorEnabled: true,
    isGeoLocationEnabled: true,
    isGlobalCategoryEnabled: true,
    isGoogleAdsBannerEnabled: true,
    isHelplineEnabled: true,
    isHomeDeliveryVisibleInFacet: false,
    isHomeDeliveryVisibleInPDP: true,
    isHomeDeliveryVisibleInPod: false,
    isHtmlValidatorEnabled: false,
    isInhouseBundleWidgetActive: false,
    isInHouseReviewSectionEnabled: true,
    isInternationalBuyVisiblePDP: true,
    isInternationalShippingVisibleInPod: true,
    isMeatStickerApplicable: true,
    isMeatStickerApplicableInPDP: true,
    isMKPProductDisabled: false,
    isMultipurposeBadgeApplicable: true,
    isMyListsEnabled: true,
    isNewBVWriteReviewEnabled: true,
    isNewCMRDesignEnabledPDP: false,
    isNewDeliveryOptionsEnabled: true,
    isNewPriceApplicable: true,
    isNewSearchVisible: true,
    isNewSizeGroupService: false,
    isNewSortingLogicForSponsoredProductsEnabled: true,
    isNewTypeaheadEnabled: true,
    isNewTypeaheadEnabledForBeta: true,
    isNewWarrantyOptionsEnabled: true,
    isNewWarrantyOptionsV2Enabled: true,
    isNewWarrantyUIEnabled: true,
    isNormalPriceCrossedOut: true,
    isOneClickButtonVisible: false,
    isOneClickCheckoutEnabled: true,
    isPDPMyListsEnabled: true,
    isPDPShippingModalEnabled: false,
    isPDPWidgetGlobalEnabled: true,
    isPhotoSlurpEnabled: false,
    isPickUpFromStoreVisibleInFacet: false,
    isPickUpFromStoreVisibleInPod: false,
    isPickupInStoreVisibleInPDP: true,
    isPictureTagEnabled: true,
    isPidAndPgidValidationDisabled: false,
    isPlansClickToGetCalledEnabled: true,
    isPlansEvaluationEnabled: true,
    isPodActionButtonEnabled: true,
    isPoliticalIdExists: true,
    isPopOverVisible: true,
    isProductOrchestratorRecoEnabled: false,
    isPromotionsEnabledInPDP: true,
    isPumPriceEnabled: false,
    isQuantityEditableEnabled: true,
    isQuantityEdittableEnabled: true,
    isQuantitySelectorVisibleInPDP: true,
    isReaconditionedLinkEnabledPDP: true,
    isRecoV2Enabled: true,
    isRedirect301EnabledForBrand: true,
    isRedirect301EnabledForPLP: true,
    isRelatedKeywordEnabled: false,
    isRelevancyForCollection: true,
    isReturnPolicyVisibleInPDP: true,
    isRootCategoryGForFacetCategoriaDisabled: false,
    IsSearchExperienceCheckbox: true,
    isSellerBusinessNameEnabled: true,
    isSellerInfoVisibleInPDP: true,
    isSellerMute: true,
    isSellerPopupEnabled: true,
    isSellerRucEnabled: true,
    isShippingAttributesEnabled: false,
    isShippingFacetVisible: false,
    isShippingFacetWithSwitchVisible: true,
    isShippingLabels404LogsDisabled: true,
    isShippingLabelsEnabledInPdp: false,
    isShippingLabelsEnabledInXlp: false,
    isSISExperienceOn: true,
    isSisSodimacPdpEnabled: false,
    isSizeEquivalencyTableVisible: false,
    isSodimacFaviconTestActivated: false,
    isStockQuantityVisibleInPDP: false,
    isStoreAvailabilityVisibleInPDP: true,
    isSurveyBarShowninPDP: false,
    isTypeAheadEnabled: true,
    isTypeCAvailableInLightbox: false,
    isTypeCProductsEnabled: false,
    isUnitForSaleEnabled: true,
    isWarrantyTooltipEnabled: true,
    isWebPushNotificationEnabled: true,
    newDiscountBadge: true,
    PDP_AB_TEST_OVERRIDE_VALUE: '',
    shouldSendContextIdToReco: true,
    shouldSendUserIdToReco: true,
    showCustomPlaceHolderForPriceRange: true,
    showListToggleButtons: true,
    showPricesForSodimacProducts: true,
    showRatingDescription: false,
    splitSearchFacetCalls: true,
    transversalBannerConfig: '{"home":true,"pdp":false,"xlp":true,"basket":true}',
    useImplicitFilterForXLP: false,
  },
};
